import React from 'react'
import { titleIfy, slugify } from '../../utils/helpers'
import CartLink from '../components/CartLink'
import LogLink from '../components/LogLink'
import { getSiteConfigField } from '../../utils/helpers'
import ConditionalLink from '../components/ConditionalLink'
import Image from '../components/Image'
import { DENOMINATION } from '../../providers/inventoryProvider'

const SearchListItem = ({ link, title, imageSrc, price, id }) =>  (
  <div className="flex flex-row gap-2 h-60">

  {getSiteConfigField('multiPageSite') === 'true' ? (
              <div>
                <a href={`/${link}`} onClick={()=>{}}>
                  <div className="h-56 flex justify-center items-center bg-light hover:bg-light-200">
                    <div className="flex flex-column justify-center items-center h-full">
                      <Image alt={title} src={imageSrc} className="w-3/5 showcase max-h-full" />
                    </div>
                  </div>
                </a>
              </div>
            ) : (
              <div>
                <ConditionalLink redirectUrl={`/${link}`} onClick={()=>{}} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
                  <div className="h-56 flex justify-center items-center bg-light hover:bg-light-200">
                    <div className="flex flex-column justify-center items-center h-full">
                      <Image alt={title} src={imageSrc} className="w-3/5 showcase max-h-full" />
                    </div>
                  </div>
                </ConditionalLink>
              </div>
            )}

    <div>
      <p className="m-4 text-center text-l font-semibold mb-1">{title}</p>
      <p className="text-center text-xs text-gray-700 mb-4">{`${DENOMINATION}${price}`}</p>
    </div>
  </div>
)

const SearchedView = (props) => {
  const { pageContext: { title = '', content: { items = [] }}} = props
  return (
    <>
      <CartLink />
      <LogLink />
      <div className="flex flex-col items-center">
        <div className="max-w-fw flex flex-col">
          <div className="pt-10 pb-8">
            <h1 className="text-5xl font-light">{titleIfy(title)}</h1>
          </div>

          <div>
            <div className="flex flex-wrap flex-col gap-6">
              {
                items.map((item, index) => {
                  return (
                    <SearchListItem
                      key={index}
                      link={slugify(item.name)}
                      id={item.id}
                      title={item.name}
                      price={item.price}
                      imageSrc={item.image}
                    />
                  )
                })
              }
            </div>
          </div>
          </div>
      </div>
    </>
  )
}

export default SearchedView